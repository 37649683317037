import "./App.css";
import Layout from "./Layout";

function App() {
  // useEffect(() => {
  //   store.dispatch(loadUser());
  //   return () => {
  //     //cleanup
  //   };
  // }, []);
  return <Layout />;
}

export default App;
