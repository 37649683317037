// ALERT
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// AUTH
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOG_OUT = "LOG_OUT";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

// TOURS
export const FETCH_TOURS = "FETCH_TOURS";
export const FETCH_TOUR = "FETCH_TOUR";
export const CREATE_TOUR = "CREATE_TOUR";
export const UPDATE_TOUR = "UPDATE_TOUR";
export const DELETE_TOUR = "DELETE_TOUR";

// REVIEWS
export const FETCH_REVIEWS = "FETCH_REVIEWS";

// OTHERES
export const STRIPE_CHECKOUT = "STRIPE_CHECKOUT";

// Queries
export const FETCH_QUERIES_SUCCESS = "FETCH_QUERIES_SUCCESS";
export const FETCH_QUERIES_FAILURE = "FETCH_QUERIES_FAILURE";

// blogs
export const FETCH_BLOGS_REQUEST = "FETCH_BLOGS_REQUEST";
export const FETCH_BLOGS_SUCCESS = "FETCH_BLOGS_SUCCESS";
export const FETCH_BLOGS_FAILURE = "FETCH_BLOGS_FAILURE";

export const WRITE_BLOG_REQUEST = "WRITE_BLOG_REQUEST";
export const WRITE_BLOG_SUCCESS = "WRITE_BLOG_SUCCESS";
export const WRITE_BLOG_FAILURE = "WRITE_BLOG_FAILURE";

export const GET_SINGLE_BLOG_REQUEST = "GET_SINGLE_BLOG_REQUEST";
export const GET_SINGLE_BLOG_SUCCESS = "GET_SINGLE_BLOG_SUCCESS";
export const GET_SINGLE_BLOG_FAILURE = "GET_SINGLE_BLOG_FAILURE";

export const GET_FEATURED_BLOG_REQUEST = "GET_FEATURED_BLOG_REQUEST";
export const GET_FEATURED_BLOG_SUCCESS = "GET_FEATURED_BLOG_SUCCESS";
export const GET_FEATURED_BLOG_FAILURE = "GET_FEATURED_BLOG_FAILURE";

export const DELETE_BLOG_REQUEST = "DELETE_BLOG_REQUEST";
export const DELETE_BLOG_SUCCESS = "DELETE_BLOG_SUCCESS";
export const DELETE_BLOG_FAILURE = "DELETE_BLOG_FAILURE";

export const UPDATE_BLOG_REQUEST = "UPDATE_BLOG_REQUEST";
export const UPDATE_BLOG_SUCCESS = "UPDATE_BLOG_SUCCESS";
export const UPDATE_BLOG_FAILURE = "UPDATE_BLOG_FAILURE";

export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
